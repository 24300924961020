<template>
  <div id="main_content">
    <section class="banner banner-three">
      <div class="container">
        <div class="banner-content-wrap-two">
          <div class="banner-content text-center">
            <div>
              <img src="@/assets/mikmic/mikmic-1.png" class="wow pixZoomIn" alt="mpckup" />
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div>
          <div class="banner-content text-center">
            <div class="col-lg-4 col-md-5"></div>
            <h3>{{ $t('click_to_system') }}</h3>
            <br />
            <div class="banner-button-container">
              <a href="https://system.mikmic.com/">
                <button
                  href="https://system.mikmic.com/"
                  class="custom-button pulse-button-custom"
                >Login</button>
              </a>

              <a href="https://system.mikmic.com/reg/new" style="margin-left: 10px;">
                <button
                  href="https://system.mikmic.com/reg/new"
                  class="custom-button pulse-button-custom"
                >Registrate</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-shape-inner">
        <div class="circle-shape wow pixFadeRight">
          <img src="@/assets/media/background/circle4.png" data-parallax="{'x' : -50}" alt="circle" />
        </div>
        <div class="shape wow pixFadeLeft">
          <img src="@/assets/media/background/shape2.png" data-parallax="{'x' : 50}" alt="shape" />
        </div>
      </div>

      <div class="bg-shape-inner">
        <div class="circle-shape wow pixFadeRight">
          <img src="@/assets/media/background/circle4.png" data-parallax="{'x' : -50}" alt="circle" />
        </div>
        <div class="shape wow pixFadeLeft">
          <img src="@/assets/media/background/shape2.png" data-parallax="{'x' : 50}" alt="shape" />
        </div>
      </div>
    </section>

    <section class="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div>
              <div class="section-title text-center">
                <h3 class="sub-title wow pixFadeUp">{{ $t('deleteAcount.title') }}</h3>
              </div>
              <p>{{ $t('deleteAcount.text1') }}</p>
              <p>{{ $t('deleteAcount.text2') }}</p>
              <ol>
                <li>{{ $t('deleteAcount.text2.li1') }}</li>
                <li>{{ $t('deleteAcount.text2.li2') }}</li>
                <li>{{ $t('deleteAcount.text2.li3') }}</li>
              </ol>
              <p>{{ $t('deleteAcount.text3') }}</p>
              <ol>
                <li>{{ $t('deleteAcount.text3.li1') }}</li>
                <li>{{ $t('deleteAcount.text3.li2') }}</li>
                <li>{{ $t('deleteAcount.text3.li3') }}</li>
                <li>{{ $t('deleteAcount.text3.li4') }}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="featured-four-ab">
      <div class="container">
        <div class="section-title text-center">
          <h3 class="sub-title wow pixFadeUp">{{ $t('download_now') }}</h3>
          <br />

          <h1 class="wow pixFadeUp">{{ $t('MIKMIC') }}</h1>
          <br />

          <div class="banner-button-container">
            <img src="@/assets/mikmic/mikmic_store_image.png" />
          </div>

          <div class="banner-button-container">
            <a href="https://play.google.com/store/search?q=mikmic&c=apps">
              <img src="@/assets/mikmic/google-store.png" class="button-store" />
            </a>

            <a
              href="https://apps.apple.com/mx/app/mikmic/id1673212928"
              class="button-store-position"
            >
              <img src="@/assets/mikmic/apple-store.png" class="button-store" />
            </a>
            <h6 class="wow pixFadeUp">{{ $t('register_mark') }}</h6>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import "swiper/css/swiper.css";
import "magnific-popup";
export default {
  name: "home",
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>